





































































































































































































import Menu from '@/components/menu/menu.vue'
import { ElForm } from 'element-ui/types/form'
import { getHotelList } from '@/api/hotel'
import { getHotelsList, postXcpmsHotel, putXcpmsHotel, deleteXcpmsHotel } from '@/api/ota'
import { postXcpmshotelroom, gethotelroomsList, putXcpmshotelroom, deleteXcpmshotelroom } from '@/api/ota'
import Vue from 'vue'
export default Vue.extend({
	components: {
		Menu,
	},
	data() {
		var check_pms_hotel_code = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('PMS酒店code不能为空'))
			}
			if (value.length < 8 || value.length > 10) {
				return callback(new Error('PMS酒店code长度为8-10'))
			}
			callback()
		}
		var check_sale_room_code = (rule, value, callback) => {
			let ischannel = (this as any).src_channel == '携程'
			if (!value && ischannel) {
				return callback(new Error('产品code不能为空'))
			}
			if ((value.length < 5 || value.length > 12) && ischannel) {
				return callback(new Error('产品code长度为5-12'))
			}
			callback()
		}
		return {
			showInfo2: true,
			hotels: [] as any, // 酒店选项
			searchForm: {
				pms_hotel_code: '',
				pms_hotel_id: '',
			},
			editHotelDialog: false,
			hotel_submit_form: {
				id: '',
				hotel_name: '',
				hotel_code: '',
				pms_hotel_code: '',
				pms_hotel_id: '',
				ar_number: '',
			},
			hotel_submit_form_rules: {
				hotel_name: [{ required: true, message: '请输入酒店名称', trigger: 'blur' }],
				hotel_code: [{ required: true, message: '请输入酒店code', trigger: 'blur' }],
				pms_hotel_id: [{ required: true, message: '请输入PMS酒店id', trigger: 'blur' }],
				ar_number: [
					{
						validator: (rule, value, callback) => {
							if (!value) {
								return callback(new Error('PMS应收账户的客户号不能为空'))
							}
							if (value.length < 1 || value.length > 5) {
								return callback(new Error('产品code长度为1-5'))
							}
							callback()
						},
						required: true,
						trigger: 'blur',
					},
				],
				pms_hotel_code: [{ validator: check_pms_hotel_code, trigger: 'blur' }],
			},
			hotel_submit_form_title: '',

			room_type_form: {
				id: undefined as string | undefined,
				sale_type: '',
				pms_room_code: '',
				sale_room_name: '',
				sale_room_code: '',
				sale_info: [
					{
						sale_room_name: '',
						sale_room_code: '',
						sale_type: '',
					},
				],
			},
			room_type_form_rules: {
				pms_room_code: [{ required: true, message: '请输入PMS房型名称', trigger: 'blur' }],
				sale_room_name: [{ required: true, message: '请输入房型名称（产品）', trigger: 'blur' }],
				sale_room_code: [{ validator: check_sale_room_code, trigger: 'blur' }],
				sale_type: [{ required: true, message: '请选择售卖类型', trigger: ['change', 'blur'] }],
			},
			editRoomDialog: false,
			room_type_form_title: '',
			orderStauts: '1',
			table_data: [],
			table_state: {
				loading: false,
			},
			topMenuList: [
				{
					name: '携程订单',
					value: '1',
					info: '携程',
					disabled: false,
				},
				{
					name: '马蜂窝订单',
					value: '2',
					info: '马蜂窝',
					disabled: false,
				},
				{
					name: '美团订单',
					value: '3',
					info: '美团',
					disabled: false,
				},
				{
					name: '飞猪订单',
					value: '4',
					info: '飞猪',
					disabled: true,
				},
				{
					name: '驴妈妈订单',
					value: '驴妈妈',
					info: '5',
					disabled: true,
				},
				{
					name: '途牛订单',
					value: '6',
					info: '途牛',
					disabled: true,
				},
			],
			mapTypeList: [
				{
					name: '酒店名称',
					value: '1',
					disabled: false,
				},
				{
					name: '房型名称',
					value: '2',
					disabled: false,
				},
			],
			src_channel: '携程',
		}
	},
	computed: {
		returnRommIndex() {
			return (index) => {
				if (this.room_type_form.sale_info.length > 1) {
					return this.src_channel + '房型名称（产品）及code' + (index + 1)
				} else {
					return this.src_channel + '房型名称（产品）及code'
				}
			}
		},
		returnChannelinfo() {
			return (info, channelStr) => {
				return info.replaceAll('携程', channelStr)
			}
		},
	},
	created() {
		getHotelList().then((res) => {
			if (res.code == 0) {
				this.hotels = res.data.rows
				this.searchForm.pms_hotel_code = this.hotels[0].miccode
				this.searchForm.pms_hotel_id = this.hotels[0].id
				this.methodsGetHotelList()
			}
		})
	},
	mounted() {},
	methods: {
		// 切换 订单类型
		changeChannelTab(data) {
			this.src_channel = data.info
			this.changeTypeTab({ value: this.orderStauts })
		},
		// 限制PMS房型名称汉字
		handleInput(value) {
			// 正则表达式匹配中文字符
			const chineseRegex = /^[\u4e00-\u9fa5]*$/
			// 如果输入值不符合正则表达式，则清除输入
			if (!chineseRegex.test(value)) {
				this.room_type_form.pms_room_code = value.replace(/[^\u4e00-\u9fa5]/g, '').trim()
			} else {
				this.room_type_form.pms_room_code = value // 如果输入合法，则更新v-model
			}
		},
		search(e) {
			let find = this.hotels.find((item) => {
				return item.id == e
			})
			if (find) {
				this.searchForm.pms_hotel_code = find.miccode
			}
			this.changeTypeTab({ value: this.orderStauts })
		},
		// 获取酒店映射表列表
		methodsGetHotelList() {
			this.table_state.loading = true
			getHotelsList({
				pms_hotel_id: this.searchForm.pms_hotel_id,
				src_channel: this.src_channel,
			})
				.then((res) => {
					if (res.code == 0) {
						let data = res.data
						this.table_data = data.map((item) => {
							return {
								...item,
							}
						})
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		// 获取房型映射表列表
		methodsGetHotelroomlist() {
			this.table_state.loading = true
			gethotelroomsList({
				pms_hotel_id: this.searchForm.pms_hotel_id,
				src_channel: this.src_channel,
			})
				.then((res) => {
					if (res.code == 0) {
						let data = res.data
						let total_data_list = [] as any
						data.forEach((item) => {
							let ishav = total_data_list.find((it) => {
								return it.name == item.pms_room_code
							})
							if (ishav) {
								ishav.children.push(item)
							} else {
								total_data_list.push({
									id: total_data_list.length + 1,
									pms_room_code: item.pms_room_code,
									name: item.pms_room_code,
									children: [item],
								})
							}
						})
						this.table_data = total_data_list
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		// 酒店新增
		hotelAdd() {
			this.hotel_submit_form_title = '新增酒店'
			this.hotel_submit_form = {
				id: '',
				hotel_name: '',
				hotel_code: '',
				pms_hotel_code: '',
				pms_hotel_id: '',
				ar_number: '',
			}
			this.editHotelDialog = true
			this.$nextTick(() => {
				;(this.$refs['hotel_form'] as ElForm).clearValidate()
			})
			// this.resetForm('hotel_form')
		},
		// 酒店编辑
		hotelEdit(row) {
			this.hotel_submit_form_title = '编辑酒店'
			this.editHotelDialog = true
			this.hotel_submit_form = { ...row }
		},
		// 酒店删除
		hotelDelete(row) {
			this.$confirm('确定要删除酒店吗？删除后该酒店不再通过RPA抓取数据！', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					deleteXcpmsHotel({
						...row,
					}).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功',
							})
						}
						this.changeTypeTab({ value: this.orderStauts })
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		changeHotelStauts(form, mystates: string) {
			let infoOpen = '确定要启用该酒店RPA吗？启用后该酒店通过RPA抓取数据到PMS系统中！'
			let infoStop = '确定要停用该酒店RPA吗？一旦停用，该酒店将不再通过RPA抓取数据到PMS系统中！'
			let info = mystates == '2' ? infoStop : infoOpen
			let states = mystates == '2' ? '停用' : '启用'
			this.$confirm(info, '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.editXcpmsHotel({
						...form,
						status: mystates,
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${states}`,
					})
				})
		},
		// 关闭dia重置表单
		closeHotelDialog() {
			this.hotel_submit_form = {
				id: '',
				hotel_name: '',
				hotel_code: '',
				pms_hotel_code: '',
				pms_hotel_id: '',
				ar_number: '',
			}
			;(this.$refs['hotel_form'] as ElForm).clearValidate()
		},
		closeRoomDialog() {
			this.room_type_form = {
				id: '',
				sale_type: '',
				pms_room_code: '',
				sale_room_name: '',
				sale_room_code: '',
				sale_info: [
					{
						sale_room_name: '',
						sale_room_code: '',
						sale_type: '',
					},
				],
			}
			;(this.$refs['room_form'] as ElForm).clearValidate()
		},
		// 房型操作
		roomAdd() {
			this.room_type_form_title = '新增房型'
			this.editRoomDialog = true
			// this.resetForm('room_form')
		},
		addRoomsListItem() {
			let item = {
				sale_room_name: '',
				sale_room_code: '',
				sale_type: '',
			}
			this.room_type_form.sale_info.push(item)
		},
		removeRoomsListItem(index: number) {
			this.room_type_form.sale_info.splice(index, 1)
		},
		roomEdit(row) {
			this.room_type_form_title = '编辑房型'
			this.editRoomDialog = true
			this.room_type_form = { ...row }
		},
		roomDelete(row: { id: number }) {
			this.$confirm('确定要删除选中的数据吗？删除后该产品不再通过RPA抓取数据！', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					deleteXcpmshotelroom({
						...row,
					}).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: res.msg,
							})
						}
						this.changeTypeTab({ value: this.orderStauts })
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		resetForm(formName: string) {
			this.$nextTick(() => {
				;(this.$refs[formName] as ElForm).resetFields()
			})
		},
		submitForm(formName: string) {
			;(this.$refs[formName] as ElForm).validate((valid) => {
				if (valid) {
					if (formName == 'hotel_form') {
						if (this.hotel_submit_form.id) {
							this.editXcpmsHotel(this.hotel_submit_form)
						} else {
							this.addXcpmsHotel()
						}
					}
					if (formName == 'room_form') {
						if (this.room_type_form.id) {
							this.editXcpmsHotelroom(this.room_type_form)
						} else {
							this.addXcpmsHotelroom(this.room_type_form)
						}
					}
				} else {
					return false
				}
			})
		},
		addXcpmsHotelroom(form) {
			postXcpmshotelroom({
				// ...form,
				pms_hotel_code: this.searchForm.pms_hotel_code,
				pms_hotel_id: this.searchForm.pms_hotel_id,
				pms_room_code: form.pms_room_code,
				sale_info: JSON.stringify(form.sale_info),
				src_channel: this.src_channel,
			}).then((res) => {
				if (res.code == 0) {
					this.$message({
						type: 'success',
						message: '新增成功',
					})
					this.editRoomDialog = false
					this.changeTypeTab({ value: this.orderStauts })
				}
			})
		},
		editXcpmsHotelroom(form) {
			putXcpmshotelroom({
				...form,
				src_channel: this.src_channel,
			}).then((res) => {
				if (res.code == 0) {
					this.$message({
						type: 'success',
						message: '操作成功',
					})
					this.editRoomDialog = false
					this.changeTypeTab({ value: this.orderStauts })
				}
			})
		},
		addXcpmsHotel() {
			postXcpmsHotel({
				hotel_name: this.hotel_submit_form.hotel_name,
				hotel_code: this.hotel_submit_form.hotel_code,
				pms_hotel_code: this.hotel_submit_form.pms_hotel_code,
				pms_hotel_id: this.hotel_submit_form.pms_hotel_id,
				status: '1',
				src_channel: this.src_channel,
				ar_number: this.hotel_submit_form.ar_number,
			}).then((res) => {
				if (res.code == 0) {
					this.$message({
						type: 'success',
						message: '新增成功',
					})
					this.editHotelDialog = false
					this.changeTypeTab({ value: this.orderStauts })
				}
			})
		},
		editXcpmsHotel(form) {
			putXcpmsHotel({
				...form,
				src_channel: this.src_channel,
			}).then((res) => {
				if (res.code == 0) {
					this.$message({
						type: 'success',
						message: '操作成功',
					})
					this.editHotelDialog = false
					this.changeTypeTab({ value: this.orderStauts })
				}
			})
		},
		changeTypeTab(e) {
			this.orderStauts = e.value
			if (e.value == '1') {
				this.methodsGetHotelList()
			} else {
				this.methodsGetHotelroomlist()
			}
		},
		tryNext() {
			this.$confirm(
				`<span style="font-size: 24px; text-align: center;"><i class="el-icon-warning" style="color: #E6A23C;"></i></span>确认错误已修正后，重新尝试，RPA将再次向PMS推送数据？`,
				{
					distinguishCancelAndClose: true,
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}
			)
				.then(() => {
					this.$message({
						type: 'info',
						message: '保存修改',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作',
					})
				})
		},
	},
})
